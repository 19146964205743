import React, { useState } from 'react';

export const TYPE_CONTACT = 'contact';
export const TYPE_COMPANY = 'company';
export const TYPE_EVENT = 'event';
export const TYPE_CONTRACT = 'contract';
export const TYPE_APPOINTMENT = 'appointment';
export const TYPE_APPOINTMENT_REPORT = 'appointment_report';
export const TYPE_TASK = 'task';
export const TYPE_MAIL = 'mail';
export const TYPE_DOCUMENT = 'document';

export const TYPES = [
  TYPE_CONTACT,
  TYPE_COMPANY,
  TYPE_EVENT,
  TYPE_CONTRACT,
  TYPE_APPOINTMENT,
  TYPE_APPOINTMENT_REPORT,
  TYPE_TASK,
  TYPE_MAIL,
  TYPE_DOCUMENT,
];
export const FormMinimizationContext = React.createContext({
  formMinimization: {},
  setFormMinimization: () => {},
});

export const FormMinimizationContextProvider = (props) => {
  const setFormMinimization = (formMinimization) => {
    setState({ ...state, formMinimization });
  };

  const initState = {
    formMinimization: {
      [TYPE_CONTACT]: {
        minimized: false,
      },
      [TYPE_COMPANY]: {
        minimized: false,
      },
      [TYPE_EVENT]: {
        minimized: false,
      },
      [TYPE_CONTRACT]: {
        minimized: false,
      },
      [TYPE_APPOINTMENT]: {
        minimized: false,
      },
      [TYPE_APPOINTMENT_REPORT]: {
        minimized: false,
      },
      [TYPE_TASK]: {
        minimized: false,
      },
      [TYPE_MAIL]: {
        minimized: false,
      },
      [TYPE_DOCUMENT]: {
        minimized: false,
      },
    },
    setFormMinimization,
  };

  const [state, setState] = useState(initState);

  return (
    <FormMinimizationContext.Provider value={state}>
      {props.children}
    </FormMinimizationContext.Provider>
  );
};
