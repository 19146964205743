import { ChakraProvider } from '@chakra-ui/react';
import CurrentUser from '../hooks/authenticationStatus';
import { FormMinimizationContextProvider } from '../components/app/FormMinimization/FormMinimizationContext';
import('../assets/css/richtext.css');
import { useHistory } from '../hooks/history';
import Head from 'next/head';
import useCustomTheme from '../hooks/useCustomTheme';

function MyApp({ Component, pageProps }) {
  useHistory();
  const [customTheme] = useCustomTheme();

  if (!customTheme) {
    return <p>Chargement du thème...</p>;
  }

  return (
    <ChakraProvider theme={customTheme}>
      <FormMinimizationContextProvider>
        <CurrentUser isUnloggedPage={pageProps.isUnloggedPage}>
          <Head>
            <title>Agires Relations Entreprises 360</title>
          </Head>
          <Component {...pageProps} />
        </CurrentUser>
      </FormMinimizationContextProvider>
    </ChakraProvider>
  );
}

export default MyApp;
