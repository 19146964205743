import { useEffect } from 'react';
import { useRouter } from 'next/router';

const HISTORY_STORAGE_KEY = 'history';

const getStorage = () => {
  const storage = globalThis?.sessionStorage;

  if (!storage) {
    throw new Error('There is no session storage so we cannot use history.');
  }

  return storage;
};

const getCurrentLocationPathName = () => globalThis.location.pathname;

const getHistory = (storage) =>
  storage.getItem(HISTORY_STORAGE_KEY)
    ? JSON.parse(storage.getItem(HISTORY_STORAGE_KEY))
    : [];

const addPath = (history, path) => {
  return [...history, path];
};

const updateHistory = (storage, history) =>
  storage.setItem(HISTORY_STORAGE_KEY, JSON.stringify(history));

export const useHistory = () => {
  const router = useRouter();

  const storeHistory = () => {
    const storage = getStorage();
    const currentPath = getCurrentLocationPathName();
    const history = getHistory(storage);
    const newHistory = addPath(history, currentPath);
    updateHistory(storage, newHistory);
  };

  useEffect(() => storeHistory, [router.asPath]);
};

export const getPreviousPath = () => {
  const storage = getStorage();
  const history = getHistory(storage);
  const length = history.length;

  if (length > 1) {
    return history[length - 2];
  }

  return null;
};

export const getCurrentPath = () => {
  const storage = getStorage();
  const history = getHistory(storage);
  const length = history.length;

  if (length >= 1) {
    return history[length - 1];
  }

  return null;
};

export const getPathAt = (index) => {
  const storage = getStorage();
  const history = getHistory(storage);

  return history[index] || null;
};
