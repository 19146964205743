import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';

const LoginPage = dynamic(() => import('../components/app/LoginPage'));

export const UserContext = React.createContext(null);
export default function CurrentUser(props) {
  const [authenticationStatus, setAuthenticationStatus] =
    useAuthenticationStatus(props.isUnloggedPage);

  if (props.isUnloggedPage) {
    return (
      <UserContext.Provider value={authenticationStatus}>
        {props.children}
      </UserContext.Provider>
    );
  }

  if (authenticationStatus.disconnected) {
    return (
      <LoginPage
        onLoginSuccess={(user) =>
          setAuthenticationStatus((state) => ({
            ...state,
            user,
            disconnected: false,
          }))
        }
      />
    );
  }

  if (!authenticationStatus.user) {
    return <p>Chargement en cours...</p>;
  }

  return (
    <UserContext.Provider value={authenticationStatus}>
      {props.children}
    </UserContext.Provider>
  );
}

export function useAuthenticationStatus(isUnloggedPage) {
  const [authenticationStatus, setAuthenticationStatus] = useState(() => ({
    disconnected: false,
    user: null,
    refresh: false,
    onUserDisconnection: () => {
      setAuthenticationStatus((state) => ({
        ...state,
        disconnected: true,
        user: null,
      }));
    },
    refreshUser: () => {
      setAuthenticationStatus((state) => ({
        ...state,
        user: null,
      }));
    },
  }));

  useEffect(() => {
    if (
      isUnloggedPage ||
      authenticationStatus.user ||
      authenticationStatus.disconnected
    ) {
      return;
    }

    async function fetchUser() {
      const response = await fetch('/api/users/me?includes=avatarUrl,contact');

      if (!response.ok) {
        setAuthenticationStatus((state) => ({
          ...state,
          user: null,
          disconnected: true,
        }));
        return;
      }

      const user = await response.json();

      setAuthenticationStatus((state) => ({
        ...state,
        disconnected: false,
        user,
      }));

      Sentry.configureScope((scope) => {
        scope.setExtra('user', {
          id: user.id,
          email: user.email,
        });
      });
    }

    fetchUser();
  }, [authenticationStatus, isUnloggedPage, authenticationStatus.refresh]);

  return [authenticationStatus, setAuthenticationStatus];
}

export function useCurrentUser() {
  return useContext(UserContext)?.user;
}

export function useOnUserDisconnection() {
  return useContext(UserContext)?.onUserDisconnection;
}

export function useRefreshUser() {
  return useContext(UserContext)?.refreshUser;
}
