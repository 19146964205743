import { getTheme } from '../appTheme';
import { useQuery } from './apiClient';
import { useEffect, useState } from 'react';

export default function useCustomTheme() {
  const [customTheme, setCustomTheme] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`api/front_end_theme`, { signal })
      .then((response) => (response.status === 200 ? response.json() : null))
      .then((frontEndTheme) => {
        if (frontEndTheme) {
          setCustomTheme(
            getTheme({
              primaryHexa: frontEndTheme.primaryHexa,
              secondaryHexa: frontEndTheme.secondaryHexa,
              tertiaryHexa: frontEndTheme.tertiaryHexa,
              secondaryLighterHexa: frontEndTheme.secondaryLighterHexa,
              lightestGrayHexa: frontEndTheme.lightestGrayHexa,
            })
          );
          return;
        }

        setCustomTheme(getTheme({}));
      })
      .catch((error) => {
        if (
          'DOMException: The operation was aborted.' === error ||
          error?.name === 'AbortError'
        ) {
          return null;
        }
      });
    return () => controller.abort();
  }, []);

  return [customTheme];
}
