import { extendTheme } from '@chakra-ui/react';
import lightenDarken from './common/color/lightenDarken';

//
// Skin (primary + secondary + tertiary)
// If there is no need for tertiary color,
// use shades of dark grays
// OR apply primaries to tertiaries
// i.e. export const TERTIARY600 = PRIMARY600
//

//
//Internals vars.
//Don't have to be exported. These a constants used only in this file.
const PRIMARY_HEXA = '#328EAE';
const TERTIARY_HEXA = '#7D4FC9';
const SECONDARY_HEXA = '#F6A651';
//
// Primary shades.
//
// Must remain in the same fundamental color than "PRIMARY", ie blue, orange, etc.
// Contrast indicates the darkness/lightness level that SHOULD be be used over the constant to
// achieve the needed contrast for accessible and readable content (ie text color versus its
// background color for instance)
export const PRIMARY = 'primary'; // Primary color. Contrasts with BLACK.
export const PRIMARY_LIGHTER = 'primary_lighter'; // Same but way more lighter. Contrasts with BLACK.
//
// Secondary shades. Contrast with BLACK (all)
//
export const SECONDARY = 'secondary'; // Secondary color.
export const SECONDARY_LIGHTER = 'secondary_lighter'; // Way more lighter.
//
// Tertiary shades. Contrast with WHITE (all)
//
export const TERTIARY = 'tertiary'; // Tertiary color.
//
// Fonts
//
// !!!!!!! Whether they are local or not, Fonts must be imported
// from within /front/.storybook/preview.js
export const FONTS_HEADING = '"Barlow Semi Condensed", Arial, sans-serif';
export const FONTS_BODY = '"Barlow", Arial, sans-serif';

//
// Skin end
//

export const OUTLINE = 'outline';
export const OUTLINE600 = 'outline600';
export const OUTLINE700 = 'outline700';

export const NEUTRAL = 'neutral';
export const NEUTRAL600 = 'neutral600';
export const NEUTRAL700 = 'neutral700';

export const GRAY = 'gray';
export const GRAY_VARIANT = 'gray_variant';
export const GRAY_SECOND_VARIANT = 'gray_second_variant';
export const LIGHT_GRAY = 'light_gray';
export const LIGHTER_GRAY = 'lighter_gray';
export const LIGHTEST_GRAY = 'lightest_gray';

export const PRIMARY_GRADIENT = 'primary_gradient';
export const PRIMARY_GRADIENT_HORIZONTAL = 'primary_gradient_horizontal';
export const SECONDARY_GRADIENT = 'secondary_gradient';
export const CARD_GRADIENT = 'cardGradient';

export const SUCCESS = 'success';
export const SUCCESS_LIGHTER = 'success_lighter';
export const SUCCESS_DARKER = 'success_darker';

export const ALERT = 'alert';
export const ALERT600 = 'alert600';
export const ALERT700 = 'alert700';

export const WARNING = 'warning';

export const DANGER = 'danger';

export const LIGHT = 'light';
export const DARK = 'dark';

export const BLACK = 'black';

export const BOX_SHADOW = 'box_shadow';
export const LIGHT_FADE = 'light_fade';

//SCHEMES
export const PRIMARY_SCHEME = 'primary_scheme';
export const SECONDARY_SCHEME = 'secondary_scheme';
export const TERTIARY_SCHEME = 'tertiary_scheme';

export const GRAY_SCHEME = 'gray_scheme';
export const NEUTRAL_SCHEME = 'neutral_scheme';
export const DARK_SCHEME = 'dark_scheme';
export const OUTLINE_SCHEME = 'outline_scheme';

export const SUCCESS_SCHEME = 'success_scheme';
export const DANGER_SCHEME = 'danger_scheme';
export const WARNING_SCHEME = 'warning_scheme';

export const getTheme = (customValues) => {
  const primaryHexa = customValues.primaryHexa || PRIMARY_HEXA;
  const secondaryHexa = customValues.secondaryHexa || SECONDARY_HEXA;
  const tertiaryHexa = customValues.tertiaryHexa || TERTIARY_HEXA;
  const secondaryLighterHexa = lightenDarken(secondaryHexa, 60);
  const primaryLighterHexa = lightenDarken(primaryHexa, 60);

  return extendTheme({
    colors: {
      [PRIMARY_SCHEME]: {
        50: lightenDarken(primaryHexa, 170),
        100: lightenDarken(primaryHexa, 120),
        500: primaryHexa,
        600: lightenDarken(primaryHexa, -40),
        700: lightenDarken(primaryHexa, -80),
        900: lightenDarken(primaryHexa, -120),
      },
      [SECONDARY_SCHEME]: {
        50: lightenDarken(secondaryHexa, 170),
        500: secondaryHexa,
        600: lightenDarken(secondaryHexa, -40),
        700: lightenDarken(secondaryHexa, -80),
      },
      [TERTIARY_SCHEME]: {
        50: lightenDarken(tertiaryHexa, 170),
        500: tertiaryHexa,
        600: lightenDarken(tertiaryHexa, -40),
        700: lightenDarken(tertiaryHexa, -80),
      },
      [OUTLINE_SCHEME]: {
        500: '#FFFFFF',
        600: '#E6E6E6',
        700: '#808080',
      },
      [NEUTRAL_SCHEME]: {
        50: '#eee',
        500: '#999999',
        600: '#8a8989',
        700: '#6d6d6d',
      },
      [GRAY_SCHEME]: {
        50: '#eee',
        500: '#B3B3B3',
        600: '#DADADA',
        700: '#606060',
      },
      [SUCCESS_SCHEME]: {
        50: '#EBF3DC',
        500: '#8CC63F',
        600: '#7a7',
        700: '#56875f',
      },
      [WARNING_SCHEME]: {
        50: '#FAE9D6',
        500: '#F6A651',
        600: '#eb9539',
        700: '#e48520',
      },
      [DANGER_SCHEME]: {
        50: '#F8E8EE',
        500: '#D4145A',
        600: '#a51047',
        700: '#920d3e',
      },
      [DARK_SCHEME]: {
        500: '#888888',
        600: '#444444',
        700: '#3a3939',
      },
      [DARK]: '#333333',
      [LIGHT]: '#ffffff',
      [BLACK]: '#000000',
      [PRIMARY]: primaryHexa,
      [SECONDARY]: secondaryHexa,
      [TERTIARY]: tertiaryHexa,
      [NEUTRAL]: '#999999',
      [PRIMARY_LIGHTER]: primaryLighterHexa,
      [SECONDARY_LIGHTER]: secondaryLighterHexa,
      [PRIMARY_GRADIENT]: `linear-gradient(0deg, ${tertiaryHexa} 0%, ${tertiaryHexa} 18.75%, ${primaryHexa} 97.4%)`,
      [PRIMARY_GRADIENT_HORIZONTAL]: `linear-gradient(-90deg, ${tertiaryHexa} 0%, ${tertiaryHexa} 18.75%, ${primaryHexa} 97.4%)`,
      [SECONDARY_GRADIENT]: `linear-gradient(270deg, ${secondaryLighterHexa} 0%, ${secondaryHexa} 98.44%)`,
      [SUCCESS]: '#8CC63F',
      [SUCCESS_LIGHTER]: '#d4edd9',
      [SUCCESS_DARKER]: '#56875f',
      [WARNING]: '#F6A651', // alert and warning are the same for the time being. TO DO: delete alert scheme and replace ALERT* const with WARNING*
      [DANGER]: '#D4145A',
      [OUTLINE]: '#FFFFFF',
      [OUTLINE600]: '#E6E6E6',
      [OUTLINE700]: '#808080',
      [LIGHTER_GRAY]: '#E6E6E6',
      [LIGHT_GRAY]: '#808080',
      [GRAY]: '#606060',
      [GRAY_VARIANT]: '#B3B3B3',
      [GRAY_SECOND_VARIANT]: '#DADADA',
      [LIGHTEST_GRAY]: '#f5f5f5',
      [BOX_SHADOW]: '0 0 1rem rgba(0, 0 , 0, .1)',
      [LIGHT_FADE]: 'rgba(255,255,255,.95)',
    },
    fonts: {
      heading: FONTS_HEADING,
      body: FONTS_BODY,
    },
    styles: {
      global: () => ({
        body: {
          ".chakra-input__group": {
            isolation: "auto !important",
          },
          color: 'default',
          bg: '#f3f3f3',
        },
      }),
    },
  });
};
